export const calculation = async (values, settings, grade, ratefile) => {
  // *********************************************
  // PREPARATION
  // *********************************************
  const result = {};

  // *** ARBEITSTAGE ***
  const pen = parseFloat(values.pen) * 0.01; // Falls mit einem anderen Sollstunden pro Woche gerechnet wird
  const stunden_pt = values.hrw / 5;

  // *** JAHRESSTUNDEN ***
  const stunden_pj = Math.round(values.hrw * settings.arbeitswochen_pj); // prettier-ignore
  const stunden_pj_effektiv = (stunden_pj / (1 + settings.ferien[values.vac]) / (1 + settings.bezahlte_feiertage)) * pen // prettier-ignore
  const stunden_pm = stunden_pj / 12;

  // *** MULTIPLIER ***
  const multiplier_options = {
    pro_stunde: stunden_pj_effektiv,
    pro_tag: stunden_pj_effektiv / stunden_pt,
    pro_monat: 12,
    pro_jahr: 1,
  };

  const multiplier = multiplier_options[values.tgp];

  //NETTOLOHN TARGET
  if (values.tgf === "nettolohn") {
    let counter = 0;
    let tempNetto = 0;
    let goal = values.tgt;
    let tempBrutto = values.tgt;
    let calc;
    values.tgf = "bruttolohn";
    while (tempNetto !== goal && counter < 10) {
      values.tgt = tempBrutto;
      calc = await calculation(values, settings, grade, ratefile);

      tempNetto = calc.netto; //alternativ (brutto - an)

      tempBrutto = tempBrutto * (2 - tempNetto / goal);
      counter += 1;
    }
    values.tgt = goal;
    values.tgf = "nettolohn";
    return calc;
  }
  // BASISSATZ / TARGET

  result.basissatz = parseFloat(values.tgt);

  let newResult;
  let basissatz = result.basissatz;
  let num = 0;
  let searching = true;

  while (searching) {
    newResult = abzuege(
      basissatz,
      settings,
      values,
      grade,
      multiplier,
      stunden_pj_effektiv,
      multiplier_options
    );
    num += 1;

    // BASISSATZ / BRUTTOLOHN CHECK
    if (values.tgf === "basissatz") {
      searching = false; // der entsprechende Basissatz wurde zuvor bereits berechnet
    }

    // BRUTTOLOHN CHECK
    if (values.tgf === "bruttolohn") {
      if (basissatz + newResult.rs_absenzen === values.tgt || num > 10) {
        searching = false;
      } else {
        basissatz =
          (values.tgt / (basissatz + newResult.rs_absenzen)) * basissatz; // passe den basissatz an und versuchs nochmas
      }
    }

    // ALL-IN LOHNKOSTEN CHECK
    if (values.tgf === "all_in_lohnkosten") {
      if (
        newResult.bruttolohn +
          newResult.ag_total +
          newResult.provisions_total ===
          values.tgt ||
        num > 10
      ) {
        searching = false;
      } else {
        basissatz =
          (values.tgt /
            (newResult.bruttolohn +
              newResult.ag_total +
              newResult.provisions_total)) *
          basissatz; // passe den basissatz an und versuchs nochmas
      }
    }

    // BILL CHECK
    if (values.tgf === "bill_rate") {
      if (
        newResult.bruttolohn +
          newResult.ag_total +
          newResult.provisions_total +
          newResult.msp +
          newResult.prf ===
          values.tgt ||
        num > 10
      ) {
        searching = false;
      } else {
        basissatz =
          (values.tgt /
            (newResult.bruttolohn +
              newResult.provisions_total +
              newResult.ag_total +
              newResult.msp +
              newResult.prf)) *
          basissatz; // passe den basissatz an und versuchs nochmas
      }
    }
  }

  // GAV / NONVGV
  newResult.config.isgav = result.isgav; // potentiell löschen

  // HOURS
  newResult.config.hours = stunden_pj_effektiv / multiplier;
  newResult.config.hours_pj = stunden_pj_effektiv;

  // QUELLENSTEUER

  if (values.ckt) {
    // Load it

    // // Define Search criteria (e.g. "06AGA2Y")
    const excemptConfessionTax = ["VS", "GE", "VD", "JU", "NE", "TI"];

    let con = parseInt(values.con) !== 0 ? "Y" : "N";
    con = !excemptConfessionTax.includes(values.can) ? con : ""; // prettier-ignore

    if (values.can === "GE") {
      if (
        values.chi === "6" ||
        values.chi === "7" ||
        values.chi === "8" ||
        values.chi === "9"
      ) {
        values.chi = "5";
      }
    }

    if (values.can === "FR") {
      if (values.chi === "7" || values.chi === "8" || values.chi === "9") {
        values.chi = "6";
      }
    }

    if (values.can === "JU" || values.can === "NE") {
      if (values.chi === "9") {
        values.chi = "8";
      }
    }

    if (values.tar === "Q") {
      if (values.can !== "GE") {
        values.chi = "9";
        values.con = "N";
        con = "N";
      }
    }

    if (values.tar === "Q") {
      if (values.can === "VS" || values.can === "VD" || values.can === "NE") {
        values.con = "N";
        con = "N";
      }
      if (values.can === "JU") {
        values.con = "Y";
        con = "Y";
      }
    }

    if (values.tar === "G") {
      values.chi = "9";
      values.con = "N";
      con = "N";
    }

    const cantonTarCodeF = ["GR", "TI", "VS"];
    if (values.tar === "F") {
      if (!cantonTarCodeF.includes(values.can)) {
        values.tar = "error";
      }
    }

    if (values.tar === "E") {
      values.chi = "0";
    }

    if (values.can === "TI") {
      if (values.tar === "M" || values.tar === "N" || values.tar === "P") {
        values.tar = "error";
      }
    }

    if (values.can === "GE") {
      if (
        values.tar === "M" ||
        values.tar === "N" ||
        values.tar === "P" ||
        values.tar === "L" ||
        values.tar === "Q"
      ) {
        values.tar = "error";
      }
    }
    const cantonTarCodeTi = ["R", "S", "T", "U"];
    if (values.can !== "TI" && cantonTarCodeTi.includes(values.tar)) {
      values.tar = "error";
    }

    let searchCriteria = "";
    if (values.tar === "HE" || values.tar === "ME" || values.tar === "NO") {
      searchCriteria = "" + values.can + values.tar  + con; // prettier-ignore
    } else {
      searchCriteria = "" + values.can + values.tar + values.chi + con; // prettier-ignore
    }

    const cantonTarCodeSF = ["BE", "BS", "BL", "JU", "NE", "SO", "VD", "VS"];
    if (values.tar === "SF" && cantonTarCodeSF.includes(values.can)) {
      searchCriteria = "" + values.can + values.tar  + con; // prettier-ignore
    }

    if (values.tar === "PEL" || values.tar === "PPA" || values.tar === "MED") {
      searchCriteria = "" + values.can + values.tar; // prettier-ignore
    }

    if (
      values.tar === "A"
      // || values.tar === "H"
    ) {
      if (values.chh === "false") {
        searchCriteria = "" + values.can + "A" + "0" + con;
      } else if (values.chh === "true") {
        if (values.chi === "0") {
          searchCriteria = "" + values.can + "A" + values.chi + con;
        } else {
          searchCriteria = "" + values.can + "H" + values.chi + con;
        }
      }
    }

    //WITHOUT API
    const regex = new RegExp(searchCriteria + "(.*)", "gm");
    const salary = (newResult.bruttolohn * multiplier) / 12;

    // // Parse it / Match it
    const tarResult = ratefile.match(regex);

    // // Iterate result and find relevant tax rate based on salary (make sure to use multiplier)
    let found = false;
    let i = 0;
    let quellensteuer = 0;
    let minquellensteuer = 0;
    let quellensteuerabzug = 0;
    let tax = 0;
    let mintax = 0;
    do {
      const item = tarResult[i];
      const start = parseInt(item.substring(20, 29)) / 100;
      const span = parseInt(item.substring(29, 38)) / 100;
      const end = start + span;

      tax = parseInt(item.substring(50, 55));
      // tax = values.qst_satz;
      mintax = parseInt(item.substring(41, 50));
      // mintax = parseInt(values.qst_minLohn);

      if (start <= salary && salary < end) {
        found = true;
      }
      i = i + 1;
    } while (!found && i < tarResult.length);

    quellensteuerabzug = Math.max((tax / 10000) * salary, mintax / 100);
    quellensteuer = (newResult.netto * multiplier) / 12 - quellensteuerabzug;

    // return effective tax (tax rate * net saray or minimum tax)

    newResult.quellensteuerlohn = (quellensteuer * 12) / multiplier;
    newResult.quellensteuerabzug = (quellensteuerabzug * 12) / multiplier;
    newResult.quellensteuersatz = tax / 100;
    // FETCH TAX-RATE AND MIN TAX FROM API

    //Calculate gross salary per Month
    const salary_gross_mo = (newResult.bruttolohn * multiplier) / 12;
    //console.log("SALARY", salary_gross_mo);

    // Fetch API function declaration
    // async function fetchTaxSourceRates(values, salary_gross_mo) {
    //   var tarif_letter = "";
    //   var nChildren = "";

    //   if (values.tar === "A" && values.chh === true && values.chi > 0) {
    //     tarif_letter = "H";
    //   } else {
    //     tarif_letter = values.tar;
    //   }
    //   if (values.tar === "A" && values.ckh === true && values.chh === false) {
    //     nChildren = "0";
    //   } else {
    //     nChildren = values.chi;
    //   }

    //   const data = {
    //     secret: "ObNaSMZiSu5F7P576utzR",
    //     values: {
    //       //QST
    //       kanton: values.can,
    //       tarif: tarif_letter,
    //       kinder: nChildren,
    //       kinder_haushalt: values.chh,
    //       konfession: values.con,
    //       // SALARY
    //       bruttolohn_pro_mon: salary_gross_mo,
    //     },
    //   };

    //   const response_RM = await fetch(
    //     "https://api.rmgroup.ch/v1/core/public/finance/taxatsource",
    //     {
    //       method: "POST",
    //       body: JSON.stringify(data),
    //     }
    //   )
    //     .then((response) => response.json())
    //     .then((data) => data);

    //   // let tax_source_rates;
    //   // tax_source_rates = await response_RM.json();
    //   // console.log("FETCH TAR PARAMS", tax_source_rates);

    //   // store taxrates in value object with temp
    //   // values.qsm = tax_source_rates.mintax * 100;
    //   // values.qsr = tax_source_rates.taxrate * 100;

    //   return response_RM;
    // }

    // // (async () => {

    // const qst_rates = await fetchTaxSourceRates(values, salary_gross_mo);

    // let tax = qst_rates.taxrate * 100;
    // let mintax = qst_rates.mintax * 100;

    // // let tax = values.qsr;
    // // let mintax = values.qsm;
    // const quellensteuerabzug = Math.max((tax / 10000) * salary_gross_mo, mintax / 100); //prettier-ignore
    // const quellensteuer = (newResult.netto * multiplier) / 12 - quellensteuerabzug; // prettier-ignore

    // newResult.quellensteuerlohn = (quellensteuer * 12) / multiplier;
    // newResult.quellensteuerabzug = (quellensteuerabzug * 12) / multiplier;
  }

  return newResult;
};

export const abzuege = (
  basissatz,
  settings,
  values,
  grade,
  multiplier,
  stunden_pj,
  multiplier_options
) => {
  const result = { basissatz: basissatz };

  // GAV CHECK
  result.isgav = (basissatz * multiplier) / stunden_pj < settings.gav_threshold  ? "gav"  : "nongav"; //prettier-ignore

  // RUECKSTELLUNG ABSENZEN
  const bezahlte_feiertage = settings.bezahlte_feiertage;
  const settings_ferienrückstellung = settings.ferien[values.vac];
  const settings_dreizehnter = result.isgav === "gav" ? settings.dreizehnter : 0; // prettier-ignore

  const ferienrueckstellung = (1 + bezahlte_feiertage) * settings_ferienrückstellung; //prettier-ignore
  const dreizehnter = (1 + bezahlte_feiertage + ferienrueckstellung) * settings_dreizehnter; //prettier-ignore
  const sonstige_absenzen = (1 + bezahlte_feiertage + ferienrueckstellung + dreizehnter) * settings.sonstige_absenzen; // prettier-ignore
  const rs_absenzen_percentage =  bezahlte_feiertage + ferienrueckstellung + dreizehnter + sonstige_absenzen // prettier-ignore

  result.rs_absenzen = result.basissatz * rs_absenzen_percentage;
  result.bezahlte_feiertage = result.basissatz * bezahlte_feiertage;
  result.ferienrueckstellung = result.basissatz * ferienrueckstellung;
  result.dreizehnter = result.basissatz * dreizehnter;
  result.sonstige_absenzen = result.basissatz * sonstige_absenzen;

  // BRUTTOLOHN
  result.bruttolohn = result.basissatz + result.rs_absenzen;

  //ZUSAETZLICHE RUECKSTELLUNG
  const pro = values.pro.filter(
    (provision) => !isNaN(parseFloat(provision.value)) && provision.label !== ""
  );
  let provisions_total = 0;
  result.pro = pro.map((provision) => {
    const item = {value: parseFloat(provision.value) / 100 * result.bruttolohn, label: provision.label} 
    provisions_total += item.value
    return item;
  }); // prettier-ignore
  result.provisions_total = provisions_total;

  // *********************************************
  // BVG VERSICHERTER LOHN
  // *********************************************

  // PREPARATION
  const bruttolohn_jahr = result.bruttolohn * multiplier;
  const uvg_regulaer = Math.min(settings.lohnkennzahlen.max_vers_uvg_lohn, bruttolohn_jahr) / multiplier; // prettier-ignore
  const uvg_ueberregulaer = Math.max(bruttolohn_jahr - settings.lohnkennzahlen.max_vers_uvg_lohn, 0) / multiplier // prettier-ignore
  let ktg_regulaer = Math.min( bruttolohn_jahr, settings.lohnkennzahlen.min_vers_ktg_lohn); //prettier-ignore
  const uvg_ueberschuss = Math.max(ktg_regulaer - settings.lohnkennzahlen.max_vers_uvg_lohn, 0) / multiplier; // prettier-ignore
  ktg_regulaer = ktg_regulaer / multiplier;

  const pensumAdjustment = values.pen / 100; // Convert percentage to decimal
  // console.log("Pensum (%):", values.pen);
  const hoursPerWeekAdjustment = values.hrw / 42; // 42 is full-time hours per week
  // console.log("Hours per Week:", values.hrw);
  // console.log("Hours per Week Adjustment:", hoursPerWeekAdjustment);

  const dynamicKoordinationsabzug =
    (settings.lohnkennzahlen.koordinationsabzug / 12) *
    pensumAdjustment *
    hoursPerWeekAdjustment;

  // console.log("Gross Annual Salary (bruttolohn_jahr):", bruttolohn_jahr);

  // console.log("Koordinationsabzug (monthly):", dynamicKoordinationsabzug);

  const annualDynamicKoordinationsabzug = dynamicKoordinationsabzug * 12;

  // console.log(
  //   "Annual Koordinationsabzug (yearly):",
  //   annualDynamicKoordinationsabzug
  // );

  // original bvg calculation
  // *** BVG VERSICHERTER OBLIGATORISCHER LOHN (bvg_vol) ***
  result.bvg_vol = 0;
  // if (bruttolohn_jahr >= settings.lohnkennzahlen.mindestjahreslohn) {

  if (bruttolohn_jahr >= 0) {
    // Abzug des Koordinationsabzugs
    result.bvg_vol =
      // bruttolohn_jahr - settings.lohnkennzahlen.koordinationsabzug; //add the pensum (42h)
      bruttolohn_jahr - annualDynamicKoordinationsabzug;

    // console.log(
    //   "BVG Versicherter Lohn before min max (bvg_vol):",
    //   result.bvg_vol
    // );

    // Sicherstellen, dass der Lohn innerhalb der Koordinationsschranken zuliegen kommt
    // result.bvg_vol = Math.max(result.bvg_vol,settings.lohnkennzahlen.min_koord_lohn); //prettier-ignore
    result.bvg_vol = Math.max(result.bvg_vol, 0);
    result.bvg_vol = Math.min(result.bvg_vol,settings.lohnkennzahlen.max_koord_lohn); //prettier-ignore

    // console.log(
    //   "BVG Versicherter Lohn after applying min max limits (bvg_vol):",
    //   result.bvg_vol
    // );
    // console.log("bruttolohn_jahr:", bruttolohn_jahr);
  }

  // *** BVG VERSICHERTER ÜBEROBLIGATORISCHER LOHN (bvg_vuel) ***
  result.bvg_vuel =
    bruttolohn_jahr - result.bvg_vol - dynamicKoordinationsabzug;
  // bruttolohn_jahr - result.bvg_vol -settings.lohnkennzahlen.koordinationsabzug; //prettier-ignore
  // Sicherstellen, dass es nicht unter 0 oder grösser als der BVG Überschuss Maximallohn ausfällt
  result.bvg_vuel = Math.max(result.bvg_vuel, 0);
  result.bvg_vuel = Math.min( result.bvg_vuel, settings.lohnkennzahlen.bvg_maximallohn * 10); //prettier-ignore

  // Speichern in gewählter Periodizität
  result.bvg_vol = result.bvg_vol / multiplier;
  result.bvg_vuel = result.bvg_vuel / multiplier;

  // console.log(
  //   "***BVG VERSICHERTER ÜBEROBLIGATORISCHER LOHN (bvg_vuel): ",
  //   result.bvg_vuel
  // );

  // *** JAHRESLOHN 1 (jahreslohn1) ***
  // Basierend auf:
  // Grade1: Jahreslohn 1 (für Risiko & Sparen) ist das tiefere von
  // - Hochgerechneter Jahreslohn –[ Koordinationsabzug (25'095.00)* Pensum (Wochensollstunden/ 42)]
  // - Max. koordinierter Jahreslohn (60'945.00)
  // (theortisch gäbe es noch ein min koordinierte jahreslohn, der ist aber bei unserem Lohngefüge nicht relevant)
  // Grade4: Versicherter Jahreslohn 1 (für Sparen)  ist das tiefere von
  // - Hochgerechneter Jahreslohn
  // - Max Jahreslohn 860'400
  // Grade 9: Versicherter Jahreslohn für Sparen und Risiko  ist das tiefere von
  // - Hochgerechneter Jahreslohn, kein Koordinationsabzug, KEINE ANPASSUNG Für pensum
  // - Max Jahreslohn 860'400
  // -> Es ist immer der gesamte lohn, da sonst nicht angeboten (und über 860k pa ist bei uns unrealistisch) (theoretisch gäbe es noch ein min koordinierte jahreslohn, der ist aber bei unserem Lohngefüge nicht relevant)

  result.jahreslohn1 = 0;
  if (grade === "grade1") {
    result.jahreslohn1 = bruttolohn_jahr - dynamicKoordinationsabzug;
    // bruttolohn_jahr - settings.lohnkennzahlen.koordinationsabzug * values.hrw / 42 // prettier-ignore
    result.jahreslohn1 = Math.min(result.jahreslohn1,settings.lohnkennzahlen.max_koord_lohn); //prettier-ignore
  } else if (grade === "grade4") {
    result.jahreslohn1 = Math.min(bruttolohn_jahr, settings.lohnkennzahlen.bvg_maximallohn * 10); //prettier-ignore
  } else if (grade === "grade9") {
    result.jahreslohn1 = Math.min( bruttolohn_jahr,settings.lohnkennzahlen.bvg_maximallohn * 10); //prettier-ignore
  }
  result.jahreslohn1 = Math.max(result.jahreslohn1, 0) / multiplier;

  // console.log("result.jahreslohn1: ", result.jahreslohn1);

  // *** JAHRESLOHN 2 (jahreslohn2) ***
  // Basierend auf:
  // Grade 1: Gleich wie Jahreslohn 1
  // Grade 4: Versicherter Jahreslohn 2 (für risiko)  ist das tiefere von
  // - Hochgerechneter Jahreslohn –Koordinationsabzug (25'095.00), KEINE ANPASSUNG Für pensum
  // - Max. OBLIG. Jahreslohn 86'040.00
  // Grade 9: Versicherter Jahreslohn 2 (überobligatorium) ist das tiefere von
  // - Hochgerechneter Jahreslohn – Max. oblig Jahreslohn (86'040.00)
  // - Max Jahreslohn 860'400 - Max. oblig Jahreslohn (86'040.00)

  result.jahreslohn2 = result.jahreslohn1 * multiplier;
  if (grade === "grade4") {
    result.jahreslohn2 = bruttolohn_jahr - settings.lohnkennzahlen.koordinationsabzug; //prettier-ignore
    result.jahreslohn2 = Math.min(result.jahreslohn2,settings.lohnkennzahlen.max_koord_lohn); //prettier-ignore
  } else if (grade === "grade9") {
    result.jahreslohn2 = bruttolohn_jahr - settings.lohnkennzahlen.bvg_maximallohn; //prettier-ignore
    result.jahreslohn2 = Math.min( result.jahreslohn2, settings.lohnkennzahlen.bvg_maximallohn * 10 - settings.lohnkennzahlen.bvg_maximallohn) // prettier-ignore
  }
  result.jahreslohn2 = Math.max(result.jahreslohn2, 0) / multiplier;

  const bvg_sparen = result.jahreslohn1;
  const bvg_risiko = grade === "grade4" ? result.jahreslohn2 : result.jahreslohn1; //prettier-ignore
  const bvg_ueberobli = grade === "grade9" ? result.jahreslohn2 : 0;

  // console.log(
  //   "bvg_sparen:",
  //   bvg_sparen,
  //   "bvg_risiko",
  //   bvg_risiko,
  //   "bvg_ueberobli:",
  //   bvg_ueberobli
  // );

  // *** GAV BASIS (gav_basis) ***
  result.gav_basis = result.isgav === "gav" ? result.bruttolohn : 0;

  // console.log("GAV:", result.isgav);

  // *********************************************
  // BEITRÄGE ARBEITGEBER
  // *********************************************

  // (
  //   ((content.config.sollstunden * values.pen * 0.01) / 42) * // footnote calculation for real GAV percentage workload
  //   100
  // )

  result.ag = {};

  // *** BVG OBLIGATORIUM RISIKO (bvg_or) ***

  if (values.age === "65_69_mit_BVG" || values.age === "65_plus_ohne_BVG") {
    result.ag.bgv_or = 0;
  } else {
    result.ag.bgv_or = bvg_risiko * settings.versicherungspraemie[grade].arbeitgeber.bvg_obligatorium; // prettier-ignore
  }
  // *** BVG OBLIGATORIUM SPAREN (bvg_os) ***
  const ag_altersgutschrift = settings.altersgutschrift[grade].arbeitgeber[values.age]; //prettier-ignore

  // console.log(
  //   "BVG OBLIGATORIUM SPAREN:",
  //   values.age,
  //   "Grade:",
  //   grade,
  //   "=",
  //   ag_altersgutschrift
  // );

  result.ag.bgv_os = bvg_sparen * ag_altersgutschrift;

  // *** BVG ÜBER-OBLIGATORIUM (bvg_ueo) ***
  result.ag.bgv_ueo = bvg_ueberobli * settings.versicherungspraemie[grade].arbeitgeber.bvg_ueberobligatorium  ; // prettier-ignore

  // *** AHV / UV / EO (ahv) ***
  const alter_array_fb = ["65_69_mit_BVG", "65_plus_ohne_BVG"];
  const freibetrag_array = {
    pro_stunde: (((1400 * 12) / stunden_pj) * values.hrw) / 42,
    pro_tag: ((((1400 * 12) / stunden_pj) * values.hrw) / 42) * 8.4,
    pro_monat: 1400,
    pro_jahr: 1400 * 12,
  };
  const freibetrag = alter_array_fb.includes(values.age) ? freibetrag_array[values.tgp] : 0; //prettier-ignore

  result.ag.ahv = Math.max(result.bruttolohn - freibetrag, 0) * settings.sozialversicherung.arbeitnehmer.ahv; // prettier-ignore

  // *** AHV VERWALTUNGSKOSTEN (ahv_verwaltung) ***
  result.ag.ahv_verwaltung = result.bruttolohn * settings.sozialversicherung.arbeitgeber.ahv_verwaltungskosten  ; // prettier-ignore

  // *** FAMILIENAUSGLEISKASSE FAK (fak) ***
  result.ag.fak = result.bruttolohn * settings.sozialversicherung.arbeitgeber.fak; //prettier-ignore

  // *** ALV I BEITRAG BIS CHF 148'200 PRO JAHR (alv1) ***
  const alter_array_alv = ["65_69_mit_BVG", "65_plus_ohne_BVG"];

  result.ag.alv1 = uvg_regulaer * settings.sozialversicherung.arbeitgeber.alv1;
  result.ag.alv1 = alter_array_alv.includes(values.age) ? 0 : result.ag.alv1;

  // *** ALV II BEITRAG AB CHF 148'200 PRO JAHR (alv2) ***
  result.ag.alv2 = uvg_ueberregulaer * settings.sozialversicherung.arbeitgeber.alv2; //prettier-ignore
  result.ag.alv2 = alter_array_alv.includes(values.age) ? 0 : result.ag.alv2;

  // *** UVG - BERUFSUNFALL BIS CHF 148'200 PRO JAHR (uvg_bu) ***
  result.ag.uvg_bu = uvg_regulaer * settings.versicherungspraemie[grade].arbeitgeber.uvg_berufsunfall  ; // prettier-ignore

  // *** UVG - ZUSATZVERSICHERUNG BIS CHF 148'200 PRO JAHR (uvg_zv) ***
  result.ag.uvg_zv = uvg_regulaer * settings.versicherungspraemie[grade].arbeitgeber.uvg_zusatzversicherung  ; // prettier-ignore

  // *** UVG - ÜBERSCH USSLOHN AB CHF 148'200 BIS 315'000 PRO JAHR (uvg_uel)***
  result.ag.uvg_uel = uvg_ueberschuss * settings.versicherungspraemie[grade].arbeitgeber.uvg_ueberschusslohn  ; // prettier-ignore

  // *** KTG BIS MAX CHF 315'000 PRO JAHR (ktg)***
  const ag_ktg_praemie = settings.ktg[grade].arbeitgeber[values.sex];
  result.ag.ktg_regulaer = ktg_regulaer * ag_ktg_praemie  ; // prettier-ignore

  // *** KANTONALER BERUFSBILDUNGSBEITRAG (kant_bbb)***
  result.ag.kant_bbb = result.bruttolohn * settings.weiteres.arbeitgeber.kant_berufsbildung  ; // prettier-ignore

  // *** BERUFSBEITRAG GAV PERSONALVERLEIH BIS CHF 148'200 PRO JAHR (bbb)***
  result.ag.bbb = result.gav_basis * settings.weiteres.arbeitgeber.berufsbeitrag  ; // prettier-ignore

  result.ag_total = 0;

  for (let index in result.ag) {
    result.ag_total += result.ag[index];
  }

  // *********************************************
  // BEITRÄGE ARBEITNEHMER
  // *********************************************

  result.an = {};

  // *** BVG OBLIGATORIUM RISIKO (bvg_or) ***
  if (values.age === "65_69_mit_BVG" || values.age === "65_plus_ohne_BVG") {
    result.an.bgv_or = 0;
  } else {
    result.an.bgv_or = bvg_risiko *settings.versicherungspraemie[grade].arbeitnehmer.bvg_obligatorium; //prettier-ignore
  }
  // *** BVG OBLIGATORIUM SPAREN (bvg_os) ***
  const an_altersgutschrift =settings.altersgutschrift[grade].arbeitnehmer[values.age]; //prettier-ignore
  result.an.bgv_os = bvg_sparen * an_altersgutschrift;

  // *** BVG OBLIGATORIUM ÜBER-OBLIGATORIUM (bvg_ueo) ***
  result.an.bgv_ueo = bvg_ueberobli * settings.versicherungspraemie[grade].arbeitnehmer.bvg_ueberobligatorium  ; // prettier-ignore

  result.an.ahv = Math.max(result.bruttolohn - freibetrag, 0) * settings.sozialversicherung.arbeitnehmer.ahv; // prettier-ignore

  // *** ALV I BEITRAG BIS CHF 148'200 PRO JAHR (alv1) ***
  result.an.alv1 = uvg_regulaer * settings.sozialversicherung.arbeitnehmer.alv1;
  result.an.alv1 = alter_array_alv.includes(values.age) ? 0 : result.an.alv1;

  // *** ALV II BEITRAG AB CHF 148'200 PRO JAHR (alv2) ***
  result.an.alv2 =uvg_ueberregulaer * settings.sozialversicherung.arbeitnehmer.alv2; //prettier-ignore
  result.an.alv2 = alter_array_alv.includes(values.age) ? 0 : result.an.alv2;

  // *** UVG - UVG - NICHTBERUFSUNFALL BIS CHF 148'200 PRO JAHR (uvg_nbu) ***
  result.an.uvg_nbu = uvg_regulaer * settings.versicherungspraemie[grade].arbeitnehmer.uvg_nichtberufsunfall  ; // prettier-ignore
  result.an.uvg_nbu = values.hrw < 8 ? 0 : result.an.uvg_nbu; // unter 8 Stunden pro Woche gibt es keine NBU

  // *** UVG - ÜBERSCHUSSLOHN AB CHF 148'200 BIS 315'000 PRO JAHR (uvg_uel)***
  result.an.uvg_uel = uvg_ueberschuss  * settings.versicherungspraemie[grade].arbeitnehmer.uvg_ueberschusslohn  ; // prettier-ignore

  // *** BERUFSBEITRAG GAV PERSONALVERLEIH BIS CHF 148'200 PRO JAHR (bbb)***
  result.an.bbb = result.gav_basis * settings.weiteres.arbeitnehmer.berufsbeitrag  ; // prettier-ignore

  result.an_total = 0;
  for (let index in result.an) {
    result.an_total += result.an[index];
  }

  // ALL-IN LOHN
  result.allin = result.bruttolohn + result.ag_total + result.provisions_total;

  // *********************************************
  // MSP / PAYROLL FEE
  // *********************************************

  // PAYROLLING
  if (values.prf === "perc_kundenrate") {
    const msp = parseFloat(values.msp) / 100;
    const prf = parseFloat(values["prf_" + grade]) / 100;
    const fee = msp + prf - prf * msp;
    const payroll_fee_rate = fee - msp;
    const kundenrate = result.allin / (1 - fee);
    result.prf = kundenrate* payroll_fee_rate; // prettier-ignore
  } else if (values.prf === "perc_bruttolohn") {
    result.prf = (result.bruttolohn * values["prf_" + grade]) / 100;
  } else if (values.prf === "CHF_pro_stunde") {
    result.prf = (multiplier_options.pro_stunde * values["prf_" + grade]) / multiplier; // prettier-ignore
  } else if (values.prf === "CHF_pro_tag") {
    result.prf = (multiplier_options.pro_tag * values["prf_" + grade]) / multiplier; // prettier-ignore
  } else if (values.prf === "CHF_pro_monat") {
    result.prf = (multiplier_options.pro_monat * values["prf_" + grade]) / multiplier; // prettier-ignore
  } else if (values.prf === "CHF_pro_jahr") {
    result.prf = (multiplier_options.pro_jahr * values["prf_" + grade]) / multiplier; // prettier-ignore
  }

  // MSP
  const zwischenresultat =
    result.bruttolohn + result.ag_total + result.provisions_total + result.prf; // Kundenrate ohne MSP
  result.msp = zwischenresultat / (1 - parseFloat(values.msp) / 100) - zwischenresultat; // prettier-ignore

  result.gebuehren = result.msp + result.prf;

  // KUNDENRATE
  result.kundenrate = result.allin + result.msp + result.prf;

  //NETTOLOHN
  result.netto = result.bruttolohn - result.an_total;
  // console.log("NETTOLOHN: ", result.netto);
  // console.log("############################################");
  // console.log();
  // CONFIG

  result.config = {};
  result.config.lohn1 = result.jahreslohn1 * multiplier;
  result.config.lohn2 = result.jahreslohn2 * multiplier;

  // result.config.sparen = bvg_sparen * multiplier;
  result.config.sparen = result.bvg_vol * multiplier;

  // result.config.risiko = bvg_risiko * multiplier;
  result.config.risiko = result.bvg_vol * multiplier;

  result.config.ueber = bvg_ueberobli * multiplier;
  result.config.multiplier = multiplier;
  result.config.gavlohn = settings.gav_threshold * stunden_pj;
  result.config.billablehours = stunden_pj / (1 + settings.ferien[values.vac]);
  result.config.billablehours =
    result.config.billablehours / (1 + settings.bezahlte_feiertage);
  result.config.sollstunden = values.hrw;

  return result;
};
